import React, { useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { httpBatchLink } from '@trpc/client'
import './App.css'
import { NotificationList } from './components/NotificationList'
import { trpc } from './utils/trpc'

import firebase from 'firebase/app'

let firebaseConfig = {
    apiKey: 'AIzaSyCuF-r4SsT2k3y9dyzmg6wxoksKmguRRCM',
    authDomain: 'notifyme-a971c.firebaseapp.com',
    databaseURL: 'https://notifyme-a971c.firebaseio.com',
    projectId: 'notifyme-a971c',
    storageBucket: 'notifyme-a971c.appspot.com',
    messagingSenderId: '43149434016',
    appId: '1:43149434016:web:3a9ddd4672ec126ecabdc5',
}

function App() {
    const [queryClient] = useState(() => new QueryClient())
    const [trpcClient] = useState(() =>
        trpc.createClient({
            links: [
                httpBatchLink({
                    url: 'https://notifyme.blauerwahl.de/backend/trpc',
                }),
            ],
        })
    )

    return (
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
                <div className="App">
                    <NotificationList />
                </div>
            </QueryClientProvider>
        </trpc.Provider>
    )
}

export default App
