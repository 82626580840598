import React from 'react'
import List from '@material-ui/core/List'
import {
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
} from '@material-ui/core'
import { trpc } from '../utils/trpc'
import { DateTime } from 'luxon'

export interface INotification {
    name: string
    chapter: string
    href: string
    img: string
    lastUpdate: string
}

const getDateString = (date: string) => {
    if (date) {
        return DateTime.fromJSDate(new Date(date)).toFormat('dd.MM.yyyy')
    }
    return ''
}

export function NotificationList() {
    const news = trpc.getNews.useQuery()
    if (!news.data) return <div>Loading...</div>

    return (
        <Paper>
            <List>
                {news.data &&
                    news.data
                        .sort((not1: INotification, not2: INotification) => {
                            if (!not2.lastUpdate) return -1
                            if (!not1.lastUpdate) return 1

                            return (
                                new Date(not2.lastUpdate).getTime() -
                                new Date(not1.lastUpdate).getTime()
                            )
                        })
                        .map((eachNotification: INotification) => {
                            return (
                                <ListItem
                                    component={'a'}
                                    href={eachNotification.href}
                                >
                                    <ListItemAvatar>
                                        <img
                                            src={eachNotification.img.toString()}
                                            alt="text"
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={eachNotification.name}
                                        secondary={`${
                                            eachNotification.chapter
                                        } ${getDateString(
                                            eachNotification.lastUpdate
                                        )}`}
                                    />
                                </ListItem>
                            )
                        })}
            </List>
        </Paper>
    )
}
