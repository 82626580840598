import { createTRPCReact } from '@trpc/react-query'
import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'
import type { AppRouter } from '../../../backend/src/trpcserver'
export const trpc = createTRPCReact<AppRouter>()

export const trpcProxy = createTRPCProxyClient<AppRouter>({
    links: [
        httpBatchLink({
            url: 'https://notifyme.blauerwahl.de/backend/trpc',
        }),
    ],
})
